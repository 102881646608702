import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppTokenService } from './app-token.service';
import { HttpHandlerService } from './http-handler.service';

@Injectable()
export class UserService {
  _httpHandler: HttpHandlerService;
  _apiUrl = environment.apiUrl;
  _profileEndPoint = 'v1/profile/';
  _profileUpdateEndPoint = 'v1/profile/update';
  _watchListEndPoint = 'v1/watchlist';
  _assetFavouritesEndPoint = 'v1/assetfavourite';
  _checkPlanValidityEndPoint = 'v1/checkplanvalidity';
  _uploadFileEndPoint = 'v1/profile/uploadfile';
  _removeProfilePictureEndPoint = 'v1/profile/remove/picture'

  constructor(private httpHandler: HttpHandlerService, private appTokenService: AppTokenService) {
    this._httpHandler = httpHandler;
  }

  public GetProfile(userId): Observable<any> {

    const full_api_url = this._apiUrl + this._profileEndPoint + userId;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public GetAssetFavourites(): Observable<any> {
    const full_api_url = this._apiUrl + this._assetFavouritesEndPoint;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public CheckPlanValidity(filterData): Observable<any> {
    const full_api_url = this._apiUrl + this._checkPlanValidityEndPoint + filterData;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public UploadFile(fileData, userId): Observable<any> {
    const full_api_url = this._apiUrl + this._uploadFileEndPoint + '/' + userId;
    const request_options = this._httpHandler.prepareRequest(
      'POST',
      full_api_url,
      fileData,
      null,
      null,
      null,
      true
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public UpdateProfile(profileData): Observable<any> {
    const full_api_url = this._apiUrl + this._profileUpdateEndPoint;
    const request_options = this._httpHandler.prepareRequest(
      'POST',
      full_api_url,
      profileData
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public RemoveProfilePic(userData): Observable<any> {

    const full_api_url = this._apiUrl + this._removeProfilePictureEndPoint;
    const request_options = this._httpHandler.prepareRequest(
      'POST',
      full_api_url,
      userData
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }
}
