import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHandlerService } from './http-handler.service';
import { environment } from './../../environments/environment';
import { AppTokenService } from './app-token.service';

@Injectable()
export class PaymentService {
  _httpHandler: HttpHandlerService;
  _apiUrl = environment.apiUrl;
  _appTokenService: AppTokenService;
  _subscriptionEndPoint = 'v1/subscription';
  _placeOrderEndPoint = 'v1/order';
  _getOrdersEndPoint = 'v1/orders';
  _checkPlanValidityEndPoint = 'v1/checkplanvalidity';
  _downloadInvoice = 'v1/downloadinvoice';

  constructor(private httpHandler: HttpHandlerService, private appTokenService: AppTokenService) {
    this._httpHandler = httpHandler;
    this._appTokenService = appTokenService;
  }

  public GetSubScriptionList(urlParam): Observable<any> {
    const full_api_url = this._apiUrl + this._subscriptionEndPoint + urlParam;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public PlaceOrder(urlParam): Observable<any> {
    const full_api_url = this._apiUrl + this._placeOrderEndPoint;
    const request_options = this._httpHandler.prepareRequest(
      'POST',
      full_api_url,
      urlParam
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public GetUserOrders(queryString): Observable<any> {

    const full_api_url = this._apiUrl + this._placeOrderEndPoint + queryString;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public CheckPlanValidity(requestObj): Observable<any> {

    const full_api_url = this._apiUrl + this._checkPlanValidityEndPoint + requestObj;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public DownloadInvoice(fiterString): Observable<any> {

    const full_api_url = this._apiUrl + this._downloadInvoice + fiterString;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }
}
